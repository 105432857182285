import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	vendorProfileData:  null,
	vendorDataFromLoginPage:null,
	vendorCourseDataFromPreviewPage:null,
	vendorMarketCampaignDataForVendorComingFromApi:null,
	// vendorCompanyPreview:null,
	isVendorOnBoard:false,
	vendorUpdatedData:null,
	vendorCoursesData:  null,
	vendorCourseThumbnail:null,
	vendorSubjectThumbnail:null,
	checkCoursreThumnail:null,
	vendorChapterThumbnail: null,
	checkChapterThumnail:null,
	vendorTopicThumbnail:null,
	vendorContentThumbnail:null,
	checkContentThumnail:null,
	dialogOpenForCourse :false,
	dialogOpenForSubject:false,
	dialogOpenForChapter:false,
	dialogOpenForTopic:false,
	dialogOpenForContent:false,
	noOfTopics:null,
	vendorCourseName: null,
	// courseName:null,
	indexUrlForVideo:null,
	profilePictureForVendor:null,
	profilePicSet:null,
	bannerPicSet:null,
	EditVendorChapterData:null,
	dialogOpenForMonetization:false,
	recentlyCreatedChapter: null,
    dataForContent:null,
	onBoardingdata:null,
	addDetailsWarning:false,
	
}

export const vendorSlice = createSlice({
	name: 'vendor',
	initialState,
	reducers: {
		setVendorDataFromLoginPage: (state, action) => {
			state.vendorDataFromLoginPage = action.payload
		},

		setVendorCourseName: (state, action) => {
			state.vendorCourseName = action.payload
		},

		setVendorCourseDataFromPreviewPage: (state, action) => {
			state.vendorCourseDataFromPreviewPage = action.payload
		},
		setVendorMarketCampaignDataForVendorComingFromApi: (state, action) => {
			state.vendorMarketCampaignDataForVendorComingFromApi = action.payload
		},
		setIsVendorOnboard: (state, action) => {
			state.isVendorOnBoard = action.payload
		},
		setAddDetailsWarning: (state, action) => {
			state.addDetailsWarning = action.payload
		},
		setVendorCompanyPreview: (state, action) => {
			state.vendorCompanyPreview = action.payload
		},
	
		setVendorCoursesData: (state, action) => {
			state.vendorCoursesData = action.payload
		},
		setVendorCourseThumbnail:(state,action) =>{
           state.vendorCourseThumbnail = action.payload
		},
		setCheckCourseThumbnail:(state, action)=>{
			state.checkCoursreThumnail = action.payload
	  },
	  setVendorSubjectThumbnail:(state,action) =>{
		state.vendorSubjectThumbnail = action.payload
	 },
		setVendorChapterThumbnail:(state, action)=>{
          state.vendorChapterThumbnail= action.payload
		},
		setCheckChapterThumbnail:(state, action)=>{
              state.checkChapterThumnail = action.payload
		},
		setVendorTopicThumbnail:(state,action) =>{
          state.vendorTopicThumbnail = action.payload
		},
		setVendorContentThumbnail:(state,action) =>{
			state.vendorContentThumbnail = action.payload
		},
		setCheckContentThumbnail:(state, action)=>{
			state.checkContentThumnail = action.payload
	  },
		setDialogOpenForCourse:(state,action)=>{
			state.dialogOpenForCourse = action.payload
		},
		setDialogOpenForSubject:(state,action)=>{
			state.dialogOpenForSubject = action.payload
		},
		setDialogOpenForChapter:(state,action)=>{
          state.dialogOpenForChapter = action.payload
		},
		setDialogOpenForTopic:(state,action) =>{
			state.dialogOpenForTopic = action.payload
		},
		setDialogOpenForContent:(state,action) =>{
			state.dialogOpenForContent = action.payload
		},
		// setCourseName:(state,action) =>{
		// 	state.courseName = action.payload
		// },
		setNoOfTopics:(state,action) =>{
			state.noOfTopics = action.payload
		},
		setIndexUrlForVideo :(state,action) =>{
			state.indexUrlForVideo = action.payload
		},
		SetProfilePictureForVendor :(state, action) =>{
			state.profilePictureForVendor = action.payload
		},
		SetProfilePicSet : (state,action) =>{
			state.profilePicSet = action.payload
		},
		SetBannerPicSet : (state,acton) => {
			state.bannerPicSet = acton.payload
		},
		SetEditVendorChapterData : (state,action) =>{
			state.EditVendorChapterData = action.payload
		},
		setOpenDialogForMonetization:(state,action) =>{
			state.dialogOpenForMonetization = action.payload
		},

		setRecentlyCreatedChapter:(state,action) =>{
			state.recentlyCreatedChapter = action.payload
		},
		setDataForContent : (state,action) =>{
			state.dataForContent = action.payload
		},
		setOnBoardingdata : (state,action) =>{
			state.onBoardingdata = action.payload
		}
	},
})

export const { 
	setVendorDataFromLoginPage,
	setVendorCourseDataFromPreviewPage,
	setVendorMarketCampaignDataForVendorComingFromApi,
	setIsVendorOnboard,
	setVendorCompanyPreview,
	setVendorCoursesData,
	setVendorCourseThumbnail,
	setCheckCourseThumbnail,
	setVendorSubjectThumbnail,
	setVendorChapterThumbnail,
	setCheckChapterThumbnail,
	setVendorTopicThumbnail,
	setCheckTopicThumbnail,
	setVendorContentThumbnail,
	setCheckContentThumbnail,
	setDialogOpenForCourse,
	setDialogOpenForSubject,
	setDialogOpenForChapter,
	setDialogOpenForTopic,
	setDialogOpenForContent,
	// setCourseName,
	setNoOfTopics,
	setIndexUrlForVideo,
	SetProfilePictureForVendor,
	SetProfilePicSet,
	SetBannerPicSet,
	setVendorCourseName,
	setVendorUpdatedData,
	SetEditVendorChapterData,
	setOpenDialogForMonetization,
	setRecentlyCreatedChapter,
	setDataForContent,
	setOnBoardingdata,
	setAddDetailsWarning,

} = vendorSlice.actions

export default vendorSlice.reducer